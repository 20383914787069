import React, { useState } from "react";
import styles from "./diy-pll-accept-price-popup.module.scss";
import Button from "../shared/button/component";
import { makeStyles } from '@material-ui/core/styles';
import priceFormatter from "../../utils/helpers/price-formatter";
import { showToastMessages, getDiySortOrderKey, isPllUser, calculateVisiblePrice } from "../../utils/helper";
import { DIY_SORT_ORDER, NUMBERS } from "../../utils/app-constants";
import InputText from "../shared/input-text/component";
import { useSelector } from "react-redux";
import { AppointmentService } from "../../service";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});
const DiyPllAcceptPricePopup = (
  {
    close,
    resetListing,
    isPriceEdit=false,
    isRefreshRequired = false,
    price,
    apptId,
    source = false
  }
) => {
  const isPll = isPllUser();
  const negotiations = useSelector(state=> state.negotiations);
  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex] || {};
  const { c24Quote, calculatedc24Quote, bestC24Quote, appointmentId, diySortOrder, diy_pll_shared_price, expected_hb, currentHB, } = currentAppointmentData;
  const currentState = getDiySortOrderKey(diySortOrder, true);
  const initialPrice = calculateVisiblePrice(currentAppointmentData?.manualQuote,currentAppointmentData?.dynamicMarginExp,currentAppointmentData?.c24Quote,currentAppointmentData?.inputAmount,currentAppointmentData?.pllMargin,currentAppointmentData?.retailMargin,isPll);
  const [inputText, setInputText] = useState(initialPrice);
  const classes = useStyles();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isPriceSubmit, setIsPriceSubmit] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);
  const [editPrice, setEditPrice] = useState(isPriceEdit);
  const onChangeText = (e) => {
    setIsPriceError(false);
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const nonFormattedVal = value && value.replace(/,/g, '') ? Number(value.replace(/,/g, '')) : "";
    setInputText(nonFormattedVal);
    if (!!isPll) {
      setInputText(nonFormattedVal);
    } else {
      if (nonFormattedVal < calculatedc24Quote || nonFormattedVal > bestC24Quote) {
        setIsPriceError(true);
      }
    }
    setIsConfirmed(false);
    if (!!nonFormattedVal && initialPrice !== nonFormattedVal) {
      setIsConfirmed(true);
    }
  };

  const onConfirmClick = () => {
    setEditPrice(false);
  };

  const onPriceSubmit = (value) => {
    setIsPriceSubmit(true);
    const cPrice = !!source ? Number(inputText.replace(/,/g, '')) : inputText;
    const params = {
      'appointmentId': apptId || appointmentId,
      'c24BuyingQuote': cPrice,
      'isPriceUpdated': !!source ? NUMBERS.ONE : value,
      ...(!!isPll && {'diy_pll': NUMBERS.ONE}),
      ...(!isPll && {'isNegoApplied': true}),
    };
    AppointmentService.updateSuggestedQuote(params)
      .then(response => {
        const { data: { detail: { message } } } = response;
        value === NUMBERS.ONE && showToastMessages(message);
      }).catch(error => {
        const { detail: { message } } = error;
        showToastMessages(message, false);
      }).finally(()=>{
        setIsPriceSubmit(false);
        setTimeout(()=>{
          if (!!isRefreshRequired) {
            resetListing();
            close();
          } else {
            close(true, cPrice);
          }
        }, 2000);
      });
  };

  return (
    <>
      {!editPrice && <div className={styles.diypllacceptPriceWrapper}>
        <p className={styles.priceText}>Are you sure of this price?</p>
        <p>Final C24 Quote ₹ {inputText}</p>
        <p className={styles.notifyLabel}>THIS PRICE WILL BE SENT TO CUSTOMER</p>
        <div className={styles.buttonWrapper}>
          <Button 
            ctaText={"Yes"}
            disabled={isPriceSubmit}
            onClick={()=> onPriceSubmit(NUMBERS.ONE)}
            classNames="blueButton" />
          <Button 
            ctaText={"No"} 
            onClick={()=> {
              if (!!isPriceEdit) {
                setInputText(initialPrice);
                close();
              } else {
                close(false);
              }
            }}
            classNames="cancelCta" 
            disabled={isPriceSubmit}/>
        </div>
      </div>}

      {editPrice && <div className={styles.diypllacceptPriceWrapper}>
        <p className={styles.priceText}>AT WHAT PRICE DO YOU WANT TO CLOSE THE DEAL?</p>
        <InputText
          label="Final C24 Quote"
          className={classes.textField}
          margin="dense"
          variant="outlined"
          onChange={onChangeText}
          value={inputText}
          inputProps={{
            maxLength: 9,
          }}
          InputLabelProps={{ style: {} }}
        />
        <p className={styles.notifyLabel}>THIS PRICE WILL BE SENT TO CUSTOMER</p>
        <div className={styles.getQuote}>
          <Button
            disabled={!isConfirmed || isPriceError}
            onClick={onConfirmClick}
            classNames="blueButton"
            ctaText="Confirm" />
        </div>
        {isPriceError && <p className={styles.errorText}>{`Please enter between ${calculatedc24Quote} & ${bestC24Quote}`}</p>}
      </div>}

    </>
  );
};

export default DiyPllAcceptPricePopup;
